<template>
  <!--  -->
  <div class="news-list" id="ulitem">
    <div class="row-ul" v-if="quantityIF">
      <div class="row-li" v-for="(item, index) in quantity" :key="index">
        <div class="tank" @click="EnterThe(item.id)">
          <div class="portrait">
            <div style="margin-right: 15px">
              <img
                class="img"
                style="width: 80px; height: 80px"
                v-if="item.logo"
                :src="item.logo"
                alt=""
                srcset=""
                @error="imageLoad(item.logo, index)"
              />
              <img
                class="img"
                v-else
                src="../static/images/18.png"
                alt=""
                srcset=""
                @error="imageLoad(item.logo, index)"
              />
            </div>
            <div class="portrait-right">
              <div class="title">
                <p class="headline">{{ cutoutproject(item.project) }}</p>
                <p class="describe">{{ item.des }}</p>
              </div>

              <div class="share-icons" v-if="!isMobile">
                <el-button
                  type="text"
                  class="website"
                  @click.stop="openWebsite(item.website)"
                >
                  <svg-icon iconClass="internet"></svg-icon>
                </el-button>

                <el-button
                  type="text"
                  class="website"
                  @click.stop="copy(item.website)"
                >
                  <svg-icon iconClass="copy"></svg-icon>
                </el-button>
              </div>

              <div class="money">
                <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
                {{ item.amount ? $tool.formatMonenyNumber(item.amount) : 0 }}
              </div>
            </div>
          </div>
          <!-- 钱 -->
          <!-- <p class="money">
            ${{ item.amount ? $tool.formatMonenyNumber(item.amount) : 0 }}
          </p> -->
          <!--  -->
          <div v-if="project" class="renewal round">
            <p class="nft">{{ item.round }}</p>
            <div class="nft">{{ item.category }}</div>
          </div>
          <!-- <div v-else class="renewal">
            <p class="renewal-time">
              {{ $t("Updateon") }}
              {{ $i18n.locale == "en" ? item.enchangeTime : item.changeTime }}
            </p>
            <div class="nft">{{ item.category }}</div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="row-ul" v-if="loading">
      <el-skeleton
        v-for="item in skeletonNum"
        :key="'skeleton-' + item"
        :loading="loading"
        class="skeleton"
        animated
      >
        <div slot="template">
          <div class="skeleton-item">
            <div class="title-line">
              <el-skeleton-item variant="text" class="image" />

              <div class="title">
                <el-skeleton-item variant="h1" class="short-line" />
                <el-skeleton-item variant="text" style="margin-right: 16px" />

                <el-skeleton-item variant="text" class="time-line" />
                <!-- <div class="btn-group">
                  <el-skeleton-item variant="text" class="btn" />
                  <el-skeleton-item variant="text" class="btn" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </el-skeleton>
    </div>
    <el-empty
      :image-size="200"
      v-if="!loading && !quantityIF"
      :description="$t('empty')"
    >
      <template #image>
        <svg-icon iconClass="empty" class="empty-icon"></svg-icon>
      </template>
    </el-empty>
  </div>
</template>

<script>
import template from "./template.vue";
import { openUrl } from "@/utils";
import { mapGetters } from "vuex";
export default {
  components: { template },
  props: {
    categoryALL: {
      type: Array,
      require: true,
    },
    project: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    skeletonNum: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      quantity: [],
      quantityIF: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["isMobile"]),
  },
  watch: {
    categoryALL: {
      handler(newval) {
        if (newval.length > 0) {
          this.quantityIF = true;
          this.quantity = newval;
        } else {
          this.quantityIF = false;
        }
        console.log(newval);
      },
    },
  },

  methods: {
    imageLoad(src, index) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        this.quantity[index].logo = ""; //显示图片未找到 图片
      }
    },

    cutoutproject(str) {
      try {
        if (str.length > 16) {
          str = str.substring(0, 16) + "...";
          return str;
        } else {
          return str;
        }
      } catch (error) {
        console.log(error);
      }
    },

    cutout(val) {
      try {
        val = val.substring(0, 35) + "...";
        return val;
      } catch (error) {
        console.log(error);
      }
    },

    EnterThe(id) {
      this.$router.push({ path: "/project-details", query: { id: id } });
    },

    openWebsite(website) {
      openUrl(website);
    },

    async copy(value) {
      await this.$copyText(value);
      this.$notify.success(this.$t("copySuccess"));
    },
  },
};
</script>

<style lang="less" scoped>
.news-list {
  .el-empty {
    opacity: 0.4;
  }
  .row-ul {
    width: 100%;
    // display: flex;
    flex-wrap: wrap;
    .row-li {
      list-style: none;
      // width: 25%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .tank {
        animation: 1s ease-in-out;
        &:active {
          opacity: 0.7;
        }
        &:hover {
          background: RGBA(56, 61, 67, 1);
        }
        overflow: hidden;
        width: 100%;
        // height: 155px;
        background: #22252c;
        border-radius: 20px;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        .money {
          // font-size: 28px;
          // font-weight: bold;
          color: #909399;
          display: flex;
          align-items: center;

          width: 20%;
          justify-content: flex-end;
        }
        .renewal {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .renewal-time {
            font-size: 14px;
            font-weight: 400;
            color: #909399;
          }
          .nft {
            height: 24px;
            padding: 0 8px;
            background: RGBA(56, 61, 67, 1);
            border-radius: 5px;
            font-size: 14px;
            color: #909399;
            text-align: center;
            line-height: 24px;
          }
        }
        .round {
          justify-content: flex-end;
          div {
            margin-left: 10px;
          }
        }
        .portrait {
          width: 100%;
          display: flex;

          .img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: #32353c;
          }
          .title {
            width: 33%;

            .headline {
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
            }
            .describe {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 14px;
              font-weight: 400;
              color: #909399;
              margin-top: 10px;
              line-height: 1.5;
            }
          }
        }

        .portrait-right {
          flex: auto;
          display: flex;
          justify-content: space-between;

          .money-icon {
            color: #21e2a9;
            padding: 0 10px;
          }
        }

        .share-icons {
          display: flex;
          align-items: center;
          .el-button {
            color: #909399;
            font-size: 20px;
            width: 32px;
            border: 1px solid;
            height: 32px;
            padding: 0;
            border: 1px solid #33363d;
          }
          .el-button:hover {
            color: #21e2a9;
          }
        }
      }
    }
  }

  .skeleton {
    list-style: none;
    // width: 25%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    &-item {
      &:active {
        opacity: 0.7;
      }
      overflow: hidden;
      width: 100%;
      // height: 155px;
      background: #22252c;
      border-radius: 20px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
    }
    .title-line {
      display: flex;
    }
    .image {
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
    .short-line {
      margin: 4px 0;
      width: 30%;
    }
    .title {
      padding-left: 25px;
      flex: auto;
    }
    .footer {
      margin: 14px 0;
      display: flex;
      justify-content: space-between;
      .time-line {
        margin-right: 15px;
      }
    }
    .btn-group {
      width: 40%;
      margin-left: auto;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 46%;
      }
    }
  }

  /deep/.el-skeleton.is-animated .el-skeleton__item {
    background: linear-gradient(
      90deg,
      rgba(50, 53, 60, 1) 25%,
      #22252c 37%,
      RGBA(56, 61, 67, 1) 63%
    );
    background-size: 400% 100%;
    -webkit-animation: el-skeleton-loading 1.3s ease infinite;
    animation: el-skeleton-loading 1.3s ease infinite;
  }
}

.isMobile .news-list {
  .row-ul .row-li .tank .money {
    width: initial;
    margin-top: 10px;
  }
  .row-ul .row-li .tank .portrait-right {
    display: block;
  }
  .row-ul .row-li .tank .portrait .title {
    width: 100%;
  }
  .row-ul .row-li .tank .round {
    margin-top: 10px;
  }
}
</style>
