<template>
  <div>
    <template v-if="loading">
      <el-skeleton
        :loading="loading"
        class="skeleton"
        animated
        v-for="item in 8"
        :key="'skeleton-' + item"
      >
        <div slot="template">
          <div class="skeleton-item flex">
            <el-skeleton-item class="skeleton-image" variant="circle" />
            <div class="skeleton-content">
              <el-skeleton-item variant="h1" class="short-line" />
              <el-skeleton-item variant="text" />
              <el-skeleton-item variant="text" />
              <el-skeleton-item variant="text" />
            </div>
          </div>
        </div>
      </el-skeleton>
    </template>
    <div class="search" v-else>
      <!-- newsList -->
      <div class="news" v-if="newsList.length > 0">
        <div class="title">
          <svg-icon iconClass="news" className="icon"></svg-icon>
          <span class="text">Information</span>
        </div>

        <template v-for="(item, index) in newsList">
          <div
            :key="index"
            class="content hover"
            v-if="index < 4"
            @click="windOpen(item.websiteUrl)"
          >
            <p class="desc">{{ item.headline }}</p>
            <p>
              {{ formatting(item.publishDate) }}
            </p>
          </div>
        </template>
        <p
          class="results"
          v-if="!newsListindex && newsList.length > 4"
          @click="Openall(1)"
        >
          See all results ({{ newsList.length - 4 }})
        </p>
        <template v-for="(item, index1) in newsList">
          <div
            class="content hover"
            :key="index1"
            v-if="newsListindex && index1 > 3"
            @click="windOpen(item.websiteUrl)"
          >
            <p class="desc">{{ item.headline }}</p>
            <p>
              {{ formatting(item.publishDate) }}
            </p>
          </div>
        </template>
      </div>

      <div class="result-row" v-if="projectList.length > 0">
        <div class="title">
          <svg-icon iconClass="project" className="icon"></svg-icon>
          <span class="text">Project</span>
        </div>
        <template v-for="(item, index1) in projectList">
          <div
            class="result-row-list cur hover"
            :key="index1"
            v-if="index1 < 4"
            @click="btn_Project('/project-details', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.project }}</p>
                <p class="DeFi">{{ item.category }}</p>
              </div>
            </div>
            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{ $tool.formatMonenyNumber(item.amount) }}
              </span>
            </div>
          </div>
        </template>
        <p
          class="results"
          v-if="!projectListindex && projectList.length > 4"
          @click="Openall(2)"
        >
          See all results ({{ projectList.length - 4 }})
        </p>
        <template v-for="(item, index1) in projectList">
          <div
            class="result-row-list cur hover"
            :key="index1"
            v-if="projectListindex && index1 > 3"
            @click="btn_Project('/project-details', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.project }}</p>
                <p class="DeFi">{{ item.category }}</p>
              </div>
            </div>
            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{ $tool.formatMonenyNumber(item.amount) }}
              </span>
            </div>
          </div>
        </template>
      </div>

      <div class="result-row" v-if="airdropList.length > 0">
        <p class="title">
          <svg-icon iconClass="airdrop" className="icon"></svg-icon>
          <span class="text">Airdrop</span>
        </p>
        <template v-for="(item, index) in airdropList">
          <div
            class="result-row-list cur hover"
            :key="index"
            v-if="index < 4"
            @click="btn_Project('/airdrop-details', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.name }}</p>
                <p class="DeFi">{{ item.token }}</p>
              </div>
            </div>
            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{
                  $tool.formatMonenyNumber(item.totalAirdropAmount) +
                  " " +
                  item.token
                }}
              </span>
            </div>
          </div>
        </template>
        <p
          class="results"
          v-if="!airdropListindex && airdropList.length > 4"
          @click="Openall(3)"
        >
          See all results ({{ airdropList.length - 4 }})
        </p>

        <template v-for="(item, index1) in airdropList">
          <div
            class="result-row-list cur hover"
            :key="index1"
            v-if="airdropListindex && index1 > 3"
            @click="btn_Project('/airdrop-details', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.name }}</p>
                <p class="DeFi">{{ item.winnerAddressNumber }}</p>
              </div>
            </div>
            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{
                  $tool.formatMonenyNumber(item.totalAirdropAmount) +
                  " " +
                  item.token
                }}
              </span>
            </div>
          </div>
        </template>
      </div>

      <div class="result-row" v-if="launchpadList.length > 0">
        <p class="title">
          <svg-icon iconClass="launchpad" className="icon"></svg-icon>
          <span class="text">Launchpad</span>
        </p>
        <template v-for="(item, index) in launchpadList">
          <div
            class="result-row-list cur hover"
            :key="index"
            v-if="index < 4"
            @click="btn_Project('/newdetails', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.name }}</p>
                <p class="DeFi">{{ item.baseAllocation }}</p>
              </div>
            </div>

            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{
                  $tool.formatMonenyNumber(item.fundraiseGoal) +
                  item.outputToken
                }}
              </span>
            </div>
          </div>
        </template>
        <p
          class="results"
          v-if="!launchpadListindex && launchpadList.leng > 4"
          @click="Openall(4)"
        >
          See all results ({{ launchpadList.length - 4 }})
        </p>
        <template v-for="(item, index) in launchpadList">
          <div
            class="result-row-list cur hover"
            :key="index"
            v-if="launchpadListindex && index > 3"
            @click="btn_Project('/newdetails', item.id)"
          >
            <div class="listbox">
              <img :src="item.logo" alt="" />
              <div class="content">
                <p class="Matter">{{ item.name }}</p>
                <p class="DeFi">{{ item.baseAllocation }}</p>
              </div>
            </div>
            <div class="money">
              <svg-icon iconClass="airdrop" className="money-icon"></svg-icon>
              <span class="money-text">
                {{
                  $tool.formatMonenyNumber(item.fundraiseGoal) +
                  item.outputToken
                }}
              </span>
            </div>
          </div>
        </template>
      </div>

      <div class="without" v-if="nodata">
        <div class="without-search">
          <svg-icon iconClass="empty" className="empty-icon"></svg-icon>
        </div>
        <p class="describe" style="color: #f0f0f0; font-size: 16px">
          {{
            $i18n.locale == "en"
              ? `No results for '${word}'`
              : `没有与“${word}”相关的结果`
          }}
        </p>
        <p class="describe">
          {{
            $i18n.locale == "en"
              ? "We couldn't find anything matching your search."
              : "我们找不到与您的搜索相符的任何内容。"
          }}
        </p>
        <p class="describe" sty>
          {{
            $i18n.locale == "en"
              ? "Try again with a different term."
              : "用不同的术语再试一次。"
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { homesearch } from "@/api/controller.js";
import ulitem from "@/components/ulitem.vue";
export default {
  components: {
    ulitem,
  },
  data() {
    return {
      word: "",
      loading: true,
      nodata: false,
      control: true,
      airdropList: [],
      airdropListindex: false,
      launchpadList: [],
      launchpadListindex: false,
      newsList: [],
      newsListindex: false,
      projectList: [],
      projectListindex: false,
    };
  },
  watch: {
    $route: {
      handler(newval) {
        this.word = newval.query.word;
        this.searchon();
      },
    },
  },
  created() {
    this.word = this.$route.query.word;
    this.searchon();
  },
  beforeDestroy() {
    this.control = false;
  },
  methods: {
    windOpen(url) {
      window.open(url);
    },
    btn_Project(path, id) {
      // 广播到/project-details - /airdrop-details -' /newdetails
      this.Event.$emit("search-project-details");
      this.$router.push({ path: path, query: { id } });
    },
    Openall(index) {
      if (index == 1) {
        this.newsListindex = true;
      } else if (index == 2) {
        this.projectListindex = true;
      } else if (index == 3) {
        this.airdropListindex = true;
      } else {
        this.launchpadListindex = true;
      }
      this.$forceUpdate();
    },
    searchon() {
      this.loading = true;
      if (!this.word) {
        this.airdropList = [];
        this.launchpadList = [];
        this.newsList = [];
        this.projectList = [];

        this.control = false;
      }
      clearTimeout(this.setout);
      this.setout = setTimeout(() => {
        if (this.control) {
          this.AggregateSearch();
        }
      }, 2000);
    },
    async AggregateSearch() {
      this.control = false; // 自动搜索失效

      this.airdropList = [];
      this.launchpadList = [];
      this.newsList = [];
      this.projectList = [];
      let res = await homesearch(this.word);
      this.airdropList = res.data.airdropList;
      this.launchpadList = res.data.launchpadList;
      this.newsList = res.data.newsList;
      this.projectList = res.data.projectList;

      if (
        this.airdropList == 0 &&
        this.launchpadList.length == 0 &&
        this.newsList == 0 &&
        this.projectList == 0
      ) {
        if (this.word) {
          this.nodata = true;
        }
      } else {
        this.nodata = false;
      }

      this.loading = false;
      this.control = true;
    },
    formatting(val) {
      try {
        return val.split(" ")[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  .title {
    font-size: 22px;
    color: #f0f0f0;
    margin-top: 15px;
    margin-bottom: 15px;
    .icon {
      color: #1edfa6;
    }

    .text {
      margin: 0 10px;
    }
  }
  .results {
    cursor: pointer;
    color: #1edfa6;
    font-size: 14px;
    line-height: 2;
  }

  .money {
    font-size: 14px;
    color: #9f9f9f;

    .money-icon {
      color: #1edfa6;
    }
    .money-text {
      margin: 0 5px;
    }
  }

  .news {
    .content {
      cursor: pointer;
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .content:hover {
      color: #f0f0f0;
    }
  }
  .result-row {
    .result-row-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-radius: 20px;

      &:hover {
        background: #383d43;
      }
    }

    .listbox {
      flex: auto;
      display: flex;
      align-items: flex-start;

      img {
        width: 60px;
        height: 60px;
      }

      .content {
        margin: 0 20px;
        .DeFi {
          margin: 10px 0;
          width: fit-content;
          height: 24px;
          padding: 0 8px;
          background: RGBA(56, 61, 67, 1);
          border-radius: 5px;
          font-size: 14px;
          color: #909399;
          text-align: center;
          line-height: 24px;
        }
      }
    }
  }
}
.without {
  text-align: center;
  line-height: 2;
}
/deep/.el-skeleton.is-animated {
  .skeleton-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  .skeleton-content {
    flex: auto;
    margin: 0 20px;
  }
  .skeleton-item {
    margin: 30px 0;
  }
  .short-line {
    width: 30%;
  }
  .el-skeleton__item {
    background: linear-gradient(
      90deg,
      rgba(50, 53, 60, 1) 25%,
      #22252c 37%,
      RGBA(56, 61, 67, 1) 63%
    );
    background-size: 400% 100%;
    -webkit-animation: el-skeleton-loading 1.3s ease infinite;
    animation: el-skeleton-loading 1.3s ease infinite;
  }
}
</style>
