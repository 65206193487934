<template>
  <div class="navigationBar">
   
  </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>
</style>
